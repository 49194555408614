import './styles.scss';

import React from 'react';
import iconArrowSmall from '@assets/icons/arrow-small.svg';
import useHover from '@assets/scripts/hooks/useHover';
// components
import Link from '@components/atoms/LinkPage';

const Breadcrumb = ({ title, link, ...otherProps }) => {
	// interaction
	const [hoverOn, hoverOff] = useHover();

	return (
		<Link
			className="breadcrumb"
			to={link}
			data-target
			{...otherProps}
			onMouseEnter={() => hoverOn()}
			onMouseLeave={() => hoverOff()}
		>
			<span className="icon-wrapper">
				<svg className="icon">
					<use xlinkHref={`#${iconArrowSmall.id}`} />
				</svg>
			</span>
			<span>{title}</span>
		</Link>
	);
};

export default Breadcrumb;
