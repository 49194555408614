import '../hero.scss';
import './styles.scss';

import React, { useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { PageContext } from '@assets/scripts/context/page-context';
import { gsap, CustomEase } from '@assets/scripts/gsap/all';
import { graphql } from 'gatsby';
// components
import Breadcrumb from '@components/atoms/Breadcrumb';
// import Link from '@components/atoms/LinkPage';
import Image from '@components/atoms/Image';

const HeroArticle = ({
	modifiers,
	className,
	breadcrumb,
	category,
	title,
	image,
	...otherProps
}) => {
	// refs
	const refBackground = useRef(null);
	const refLine = useRef(null);
	// vars
	const baseClass = 'hero--article';
	const defaultClass = 'hero';
	const rootClass = cx(baseClass, className, defaultClass, {
		[`${baseClass}--${modifiers}`]: modifiers,
	});
	const {
		tlListMenuOpen,
		tlListMenuClose,
		tlLabelsOpen,
		tlLabelsClose,
	} = useContext(PageContext);
	gsap.registerPlugin(CustomEase);

	// --------------------------------
	// #region Hooks
	// --------------------------------

	// 📢 setup component
	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		// ⏳ save timeline functions
		tlListMenuOpen.current['heroArticle'] = tlMenuOpen;
		tlListMenuClose.current['heroArticle'] = tlMenuClose;

		return () => {
			delete tlListMenuOpen.current['heroArticle'];
			delete tlListMenuClose.current['heroArticle'];
		};
	}, []);
	/* eslint-enable react-hooks/exhaustive-deps */

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Functions
	// --------------------------------

	// ✅ Menu Open: header toggle animation
	const tlMenuOpen = () => {
		// amount of scale to be applied on line and background
		let scaleBackground =
			window.innerHeight / refBackground.current.offsetHeight;
		let scaleLine = window.innerHeight / refLine.current.offsetHeight;

		const tl = gsap.timeline();
		tl
			// article menu background
			.to(
				refBackground.current,
				{
					duration: 0.45,
					ease: CustomEase.create('custom', '1, 0, 0.98, 1'),
					scaleY: scaleBackground,
					transformOrigin: 'top',
				},
				0
			)
			// article menu line
			.to(
				refLine.current,
				{
					duration: 0.45,
					ease: CustomEase.create('custom', '1, 0, 0.98, 1'),
					scaleY: scaleLine,
					transformOrigin: 'top',
				},
				tlLabelsOpen.showMenu
			);
		return tl;
	};

	// ❌ Menu Close: header toggle animation
	const tlMenuClose = () => {
		const tl = gsap.timeline();
		tl
			// article menu background
			.to(
				refBackground.current,
				{
					duration: 0.45,
					ease: CustomEase.create('custom', '0.02, 0, 0, 1'),
					scaleY: 1,
					transformOrigin: 'top',
				},
				tlLabelsClose.showPage + 0.2
			)
			// article menu line
			.to(
				refLine.current,
				{
					duration: 0.45,
					ease: CustomEase.create('custom', '0.02, 0, 0, 1'),
					scaleY: 1,
					transformOrigin: 'top',
				},
				tlLabelsClose.showPage - 0.2
			);
		return tl;
	};

	// --------------------------------
	// #endregion
	// --------------------------------

	return (
		<div className={rootClass} {...otherProps}>
			<div className="hero__top">
				<span
					className="hero__line"
					ref={refLine}
					data-animation-page-line
				></span>
				{breadcrumb ? (
					<div className="hero__breadcrumbs" data-animation-page>
						<Breadcrumb {...breadcrumb} />
					</div>
				) : null}
			</div>
			<div className="hero__inner">
				{category && (
					<p className="uptitle" data-animation-page>
						{category}
					</p>
				)}

				<h1
					className="hero-title"
					data-animation-page
					dangerouslySetInnerHTML={{
						__html: title,
					}}
				/>
				{image ? (
					<figure className="hero__img" data-animation-page>
						<Image {...image} />
					</figure>
				) : null}
			</div>
			<span
				className="hero__background"
				ref={refBackground}
				data-animation-page-bg
			></span>
		</div>
	);
};

HeroArticle.propTypes = {
	modifiers: PropTypes.string,
	className: PropTypes.string,
	breadcrumb: PropTypes.object,
	category: PropTypes.string,
	title: PropTypes.string.isRequired,
	image: PropTypes.object,
};

export default HeroArticle;

export const query = graphql`
	fragment HeroArticleImageFragment on WPGraphQL_Post {
		featuredImage {
			sourceUrl # necessary for imageFile resolver to work
			mimeType
			imageFile {
				publicURL
				childImageSharp {
					# TODO: define sizes?
					fluid(maxWidth: 2500, quality: 100) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
		}
	}
`;
